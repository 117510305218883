<template>
  <van-button type="primary" @click="triggerCountdown" :disabled="isCounting">
    发送验证码
    {{ !isCounting ? '' : `(${countdownTime}s)` }}
  </van-button>
</template>

<script setup lang="ts">
const { countdownTime, triggerCountdown, isCounting } = useVueCountdown(3);
</script>
